@import "src/styles/variables";

.links {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .link {
    padding: 50px;
    display: flex;
    align-items: center;
    height: 70px;
    text-align: center;
    background-color: $primary;
    color: $white;
    font-size: 1.5rem;
    &:hover {
      text-decoration: none;
      background-color: lightcoral; } } }
